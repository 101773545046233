import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './App.css';

// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const MovieDetails = lazy(() => import('./pages/MovieDetails'));
const Anime = lazy(() => import('./pages/Anime'));
const Series = lazy(() => import('./pages/Series'));
const Movies = lazy(() => import('./pages/Movies'));
const Kdrama = lazy(() => import('./pages/Kdrama'));
const SearchResults = lazy(() => import('./pages/Search'));

// Error Boundary component
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong. Please try again later.</div>;
    }

    return this.props.children;
  }
}

const App = () => {
  const [category, setCategory] = useState('home');

  return (
    <Router>
      <div className="App">
        <Navbar setCategory={setCategory} />
        <ErrorBoundary>
          <Suspense fallback={<div className="loading-spinner">Loading...</div>}>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/movie/:id" element={<MovieDetails />} />
              <Route path="/search" element={<SearchResults />} />
              <Route path="/anime" element={<Anime />} />
              <Route path="/series" element={<Series />} />
              <Route path="/movies" element={<Movies />} />
              <Route path="/kdrama" element={<Kdrama />} />
            </Routes>
          </Suspense>
        </ErrorBoundary>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
