import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance'; 
import "./Navbar.css"

const SearchDropdown = ({ searchQuery }) => {
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axiosInstance.get(`/api/search?query=${encodeURIComponent(searchQuery)}`);
        setSearchResults(response.data.results);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    if (searchQuery.trim() !== '') {
      fetchSearchResults();
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const handleResultClick = (movieId) => {
    navigate(`/movie/${movieId}`);
  };

  return (
    <div className="search-dropdown">
      {searchResults.length > 0 ? (
        <ul className="search-results-list">
          {searchResults.map((result) => (
            <li
              key={result._id}
              className="search-result-item"
              onClick={() => handleResultClick(result._id)}
            >
              <img src={result.image} alt={result.title} />
              <h3>{result.title}</h3>
            </li>
          ))}
        </ul>
      ) : (
        <p className="no-results">No results found.</p>
      )}
    </div>
  );
};

export default SearchDropdown;
