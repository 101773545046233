import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaFilm } from 'react-icons/fa'; 
import SearchDropdown from './SearchDropdown';
import './Navbar.css';

const Navbar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchDropdown, setShowSearchDropdown] = useState(false);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
          searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setShowSearchDropdown(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setShowSearchDropdown(true);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim() !== '') {
      navigate(`/search?query=${encodeURIComponent(searchQuery)}`);
      setSearchQuery('');
      setShowSearchDropdown(false);
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <FaFilm className="icon" aria-label="Movies World Icon" /> {/* Represents the M icon */}
          <span>Movies</span>
          <span className="highlight">world</span>
        </div>
        <ul className="navbar-menu">
          <li className="navbar-item"><Link to="/">Home</Link></li>
          <li className="navbar-item"><Link to="/anime">Anime</Link></li>
          <li className="navbar-item"><Link to="/series">Series</Link></li>
          <li className="navbar-item"><Link to="/movies">Movies</Link></li>
          <li className="navbar-item"><Link to="/kdrama">Kdrama</Link></li>
        </ul>
        <form className="navbar-search" onSubmit={handleSearchSubmit}>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
            onFocus={() => setShowSearchDropdown(true)}
            ref={searchInputRef}
            aria-label="Search"
          />
          <button type="submit" aria-label="Search button">
            <i className="fas fa-search"></i>
          </button>
          {showSearchDropdown && (
            <div ref={dropdownRef} className="search-dropdown">
              <SearchDropdown searchQuery={searchQuery} />
            </div>
          )}
        </form>
      </div>
    </nav>
  );
};

export default Navbar;
