import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-about">
        <h3>About Us</h3>
        <p>
          Moviesworld.store Website Does Not Rip Or Host Any Files On Its Servers. All Files Or Contents Hosted On Third Party Websites. This Website Doesn't Accept The Responsibility For Contents Hosted On Third Party Websites. Also,  Moviesworld.store Doesn't Rip/Pirate Any File. We Just Collect Links From Other Websites. Thank You for Using  Moviesworld.store
        </p>
      </div>
      <div className="footer-links">
        <Link to="/">Home</Link>
        <Link to="/site-disclaimer">Site Disclaimer</Link>
        <Link to="/dmca">DMCA</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div className="footer-copyright">
        <p>© 2024  Moviesworld.store Official Website All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
